import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

type DiscountModalShowRequestedEvent = CustomEvent<{
  discountCode: string;
  message: string;
}>;

class ProductDiscountModal extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-price',
      'data-compare-at-price',
      'data-currency-symbol',
      'data-discount-code',
      'data-discount-percentage',
      'data-discount-override'
    ];
  }

  readonly dataset!: {
    /**
     * The value to render in the subunit of the currency. Because we mostly work with USD, this
     * is referred to as cents.
     */
    compareAtPrice: string;

    /**
     * Currency ISO Code
     */
    currencyCode: string;

    /**
     * The symbol of the currency.
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * The discount code to apply for discount
     *
     * @example "USD"
     */
    discountCode: string;

    /**
     * Optional discount message override which replaces text of the discount message CTA
     */
    discountOverride: string;

    /**
     * The discount percentage applied to product for discount code
     *
     * Floating point string.
     */
    discountPercentage: string;

    /**
     * A message to be displayed as the content of the modal window
     */
    message: string;

    /**
     * The value to render in the subunit of the currency. Because we mostly work with USD, this
     * is referred to as cents.
     */
    price: string;
  };

  public shadowRoot!: ShadowRoot;
  private discountPrice: number;
  private displayCode: string;

  private onButtonClickedBound = this.onButtonClicked.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const message = this.shadowRoot.querySelector<HTMLElement>('button');
    message.addEventListener('click', this.onButtonClickedBound);

    this.render();
  }

  public disconnectedCallback() {
    const message = this.shadowRoot.querySelector<HTMLElement>('button');
    message?.removeEventListener('click', this.onButtonClickedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (!this.isConnected || oldValue === newValue) {
      return;
    }

    if (!this.dataset.discountCode || !this.dataset.discountPercentage) {
      return;
    }

    const price = parseInt(this.dataset.price, 10);

    let compareAtPrice = parseInt(this.dataset.compareAtPrice, 10);
    if (!Number.isInteger(compareAtPrice)) {
      compareAtPrice = price;
    }

    const discountPercentage = parseFloat(this.dataset.discountPercentage);
    this.discountPrice = Math.round(price - (price * (discountPercentage / 100)));

    // Support CSV discount code string. We only want to display the first code if there are many
    // codes present. This assumes the code is well-formed.
    const codes = this.dataset.discountCode.split(',');
    // This extra conditional is just paranoia, might not be needed
    this.displayCode = codes.length === 0 || codes[0].length === 0 ?
      this.dataset.discountCode :
      codes[0];

    this.render();
  }

  private render() {
    const message = this.shadowRoot.querySelector<HTMLElement>('.message');
    message.innerHTML = '';

    if (this.dataset.discountOverride) {
      message.textContent = this.dataset.discountOverride;
    } else {
      const price = document.createElement('price-element');
      price.dataset.currencyCode = this.dataset.currencyCode;
      price.dataset.currencySymbol = this.dataset.currencySymbol;
      price.dataset.cents = this.discountPrice.toString();
      message.textContent = 'with offer';
      message.prepend(price);
    }

    if ((this.discountPrice || this.dataset.discountOverride) && this.displayCode) {
      const component = this.shadowRoot.querySelector<HTMLElement>('.discount-message');
      component.classList.remove('hidden');
    }
  }

  private onButtonClicked(_event: MouseEvent) {
    this.showModal();
  }

  private getMessage() {
    if (this.dataset.message) {
      return this.dataset.message;
    }

    // return a default message if no message is provided
    return `Get it for&nbsp;<price-element
    data-currency-code="${this.dataset.currencyCode}"
    data-currency-symbol="${this.dataset.currencySymbol}"
    data-cents="${this.discountPrice}"></price-element>&nbsp;w/
    code <span class="code">${this.displayCode}</span>`;
  }

  private showModal() {
    type Detail = WindowEventMap['discount-modal-show-requested']['detail'];
    const modalShowEvent = new CustomEvent<Detail>('discount-modal-show-requested', {
      detail: {
        discountCode: this.displayCode,
        message: this.getMessage()
      }
    });
    dispatchEvent(modalShowEvent);
  }
}

declare global {
  interface WindowEventMap {
    'discount-modal-show-requested': DiscountModalShowRequestedEvent;
  }

  interface HTMLElementTagNameMap {
    'product-discount-modal': ProductDiscountModal;
  }
}

if (!customElements.get('product-discount-modal')) {
  customElements.define('product-discount-modal', ProductDiscountModal);
}
