import './product-discount-message/index.js';
import './product-discount-modal/index.js';

function onDiscountModalShowRequested(event: WindowEventMap['discount-modal-show-requested']) {
  const modal = document.querySelector<HTMLElement>('#discount-modal');

  const results = modal.querySelectorAll<HTMLElement>('.copy-result span');
  for (const result of results) {
    result.classList.add('hidden');
  }

  const content = modal.querySelector<HTMLElement>('.content');
  content.innerHTML = event.detail.message;

  const code = modal.querySelector<HTMLElement>('.discount-code .code');
  code.innerText = event.detail.discountCode;

  modal.removeAttribute('inert');
  modal.classList.remove('hidden');

  addEventListener('shroud-clicked', onDiscountModalCloseRequested);

  type Detail = WindowEventMap['modal-show-requested']['detail'];
  const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
    { detail: { element: modal } }
  );
  dispatchEvent(modalShowEvent);
}

function onDiscountModalCloseRequested(_event: Event) {
  const modal = document.querySelector<HTMLElement>('#discount-modal');
  modal.classList.add('hidden');
  modal.setAttribute('inert', '');

  removeEventListener('shroud-clicked', onDiscountModalCloseRequested);

  type Detail = WindowEventMap['modal-close-requested']['detail'];
  const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested',
    { detail: { element: modal } }
  );
  dispatchEvent(modalCloseEvent);
}

function onCopyClicked(_event: MouseEvent) {
  const modal = document.querySelector<HTMLElement>('#discount-modal');

  const code = modal.querySelector<HTMLElement>('.code');
  const codeText = (code.innerText || '').trim();

  let clipboardWrite;
  if (navigator.clipboard && navigator.clipboard.writeText) {
    clipboardWrite = navigator.clipboard.writeText.bind(navigator.clipboard);
  } else {
    clipboardWrite = (_: string) => Promise.reject(new Error('writeText not supported'));
  }

  clipboardWrite(codeText).then(() => {
    const result = modal.querySelector<HTMLElement>('.copy-result .success');
    result.classList.remove('hidden');
  }).catch(() => {
    const result = modal.querySelector<HTMLElement>('.copy-result .error');
    result.classList.remove('hidden');
  }).finally(() => {
    setTimeout(() => {
      const results = modal.querySelectorAll<HTMLElement>('.copy-result span');
      for (const result of results) {
        result.classList.add('hidden');
      }
    }, 2000);
  });
}

function onDOMContentLoaded(_event: Event) {
  addEventListener('discount-modal-show-requested', onDiscountModalShowRequested);

  const copyButton = document.querySelector<HTMLElement>('#discount-modal .copy');
  copyButton.addEventListener('click', onCopyClicked);

  const closeButton = document.querySelector<HTMLElement>('#discount-modal .close');
  closeButton.addEventListener('click', onDiscountModalCloseRequested);
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
