import type { CustomElement } from '@integrabeauty/custom-elements';
import styles from './index.scss';

/**
 * For the standard PDP we want to add messaging just like on product cards that shows that they
 * will get a discount. The discount messaging is based on a products metafield values uses
 * namespace to populate this data is "discount" with key "code" representing the discount code and
 * key "percentage" representing discount percentage.
 *
 * The element will use these values if present to render the discount message.
 *
 * Because we have not yet converted the product details pages over to using a element to render, we
 * have to have constituent elements assembled together
 */
class ProductDiscountMessage extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-price',
      'data-compare-at-price',
      'data-currency-symbol',
      'data-discount-code',
      'data-discount-percentage',
      'data-message-override'
    ];
  }

  readonly dataset!: {
    /**
     * The value to render in the subunit of the currency. Because we mostly work with USD, this
     * is referred to as cents.
     */
    compareAtPrice: string;

    /**
     * Currency ISO Code
     */
    currencyCode: string;

    /**
     * The symbol of the currency.
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * The discount code to apply for discount
     *
     * @example "USD"
     */
    discountCode: string;

    /**
     * The discount percentage applied to product for discount code
     *
     * Floating point string.
     */
    discountPercentage: string;

    /**
     * A message override to be displayed instead of standardized percentage discount
     *
     * @example "USD"
     */
    messageOverride: string;

    /**
     * The value to render in the subunit of the currency. Because we mostly work with USD, this
     * is referred to as cents.
     */
    price: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    if (this.dataset.messageOverride) {
      this.shadowRoot.innerHTML = `<style>${styles}</style>${this.dataset.messageOverride}`;
      return;
    }

    // We do need to apply the additional condition of discount percentage not equaling 0 to account
    // for offer-1 overrides using just the final sale price only. This is because we are testing
    // against a dataset value which will always be a string, and negating a value of '0' will not
    // product a false truthy as negating the numerical value 0.

    if (!this.dataset.discountCode || !this.dataset.discountPercentage) {
      return;
    }

    const price = parseInt(this.dataset.price, 10);

    let compareAtPrice = parseInt(this.dataset.compareAtPrice, 10);
    if (!Number.isInteger(compareAtPrice)) {
      compareAtPrice = price;
    }

    const discountPercentage = parseFloat(this.dataset.discountPercentage);
    const discountPrice = Math.round(price - (price * (discountPercentage / 100)));
    const savingsPercentage = 100 - Math.round(discountPrice / compareAtPrice * 100);

    // TODO: we may want to implement some safety for the value of product.metafields_discount_code
    // as we are expecting a string of discount code but we may get html

    // TODO: do not use &nbsp; for formatting, use css

    // Support CSV discount code string. We only want to display the first code if there are many
    // codes present. This assumes the code is well-formed.
    const codes = this.dataset.discountCode.split(',');
    // This extra conditional is just paranoia, might not be needed
    const displayCode = codes.length === 0 || codes[0].length === 0 ?
      this.dataset.discountCode :
      codes[0];

    const text = `Get it for&nbsp;<price-element
      data-currency-code="${this.dataset.currencyCode}"
      data-currency-symbol="${this.dataset.currencySymbol}"
      data-cents="${discountPrice}"></price-element>&nbsp;w/
      code <span class="code">${displayCode}</span>
      (${savingsPercentage}% savings!)`;

    this.shadowRoot.innerHTML = `<style>${styles}</style>${text}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'product-discount-message': ProductDiscountMessage;
  }
}

if (!customElements.get('product-discount-message')) {
  customElements.define('product-discount-message', ProductDiscountMessage);
}
